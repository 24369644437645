<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          v-model="emailValidationStatus"
          :items="emailValidationStatusAvailableFilters"
          :label="$trans('filter')"
          outlined
          dense
          hide-details
          small-chips
          deletable-chips
        />
      </v-col>
      <v-col cols="12" sm="9">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";

export default {
  name: "NewsletterFilter",
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "newsletter-list-filters",
      emailValidationStatus: null,
      selectedFiltersSearch: null,
      emailValidationStatusAvailableFilters: [
        {
          text: this.$trans("email_is_valid"),
          value: "is_valid",
        },
        {
          text: this.$trans("email_is_not_valid"),
          value: "is_not_valid",
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        emailValidationStatus: this.emailValidationStatus,
        selectedFiltersSearch: this.selectedFiltersSearch,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.emailValidationStatus === "is_valid") {
        data.is_valid = 1;
      }

      if (this.emailValidationStatus === "is_not_valid") {
        data.is_valid = 0;
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
  },
};
</script>
