var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("email"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.email)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.item.email)}}},[_vm._v(" "+_vm._s(_vm.item.email)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("created_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.item.created_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("verified_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.verified_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.item.verified_at,_vm.$helpers.hourDayMonthAndYearDate)))]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('newsletter-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"newsletter":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }