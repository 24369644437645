<template>
  <div v-if="!isSending" class="list mb-16">
    <no-data-info
      v-if="!newsletterExist"
      :description="$trans('no_newsletter_info')"
      :button-text="$trans('add_newsletter_record')"
      @click="addEmailToNewsletterAction"
    />
    <template v-else>
      <newsletter-filter
        @filtersChanged="filtersChanged"
        @filtersRestored="filtersRestored"
      />
      <v-data-table
        v-model="selected"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :loading="isLoadingTable"
        :loading-text="$trans('loading')"
        :no-data-text="$trans('nothing_found_here')"
        :no-results-text="$trans('nothing_found_here')"
        :options.sync="tableOptions"
        :server-items-length="total"
        :show-select="!noData"
        class="calendesk-datatable"
        mobile-breakpoint="960"
      >
        <template #[`header.data-table-select`]>
          <div class="text-center">
            <v-simple-checkbox
              v-model="selectAllState"
              :ripple="false"
              :indeterminate="isIndeterminateForSelectAll"
              color="primary"
              @input="selectAllItemsOnChange"
            />
          </div>
        </template>

        <template #[`item`]="{ item }">
          <newsletter-row
            :item="item"
            :is-selected="
              isRowSelected({
                id: item.id,
              })
            "
            @selectOnChange="itemRowEventOnSelectChange"
          />
        </template>
      </v-data-table>
      <table-footer-menu
        :on-select-actions="onSelectActions"
        :selected="selected"
        :is-loading-in-select-menu="isLoadingInSelectMenu"
        :selection-map="
          (rowItem) => {
            return {
              id: rowItem.item.id,
              email: rowItem.item.email,
            };
          }
        "
      />
    </template>
  </div>
</template>

<script>
import statsActions from "@/calendesk/mixins/statsActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import { mapActions, mapGetters } from "vuex";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import newsletterActions from "@/calendesk/mixins/newsletterActions";
import NewsletterFilter from "@/views/dashboard/pages/Notifications/Newsletter/components/NewsletterFilter.vue";
import NewsletterRow from "@/views/dashboard/pages/Notifications/Newsletter/components/NewsletterRow.vue";

export default {
  name: "NewsletterList",
  components: {
    NewsletterRow,
    NewsletterFilter,
    TableFooterMenu,
    NoDataInfo,
  },
  mixins: [newsletterActions, statsActions, sharedList],
  data() {
    return {
      cacheId: "newsletter-list",
      requestActionName: "fetchNewsletterList",
      statsMode: this.$helpers.statsMode.newsletter,
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          sortable: true,
          value: "id",
          class: "header-style",
        },
        { text: this.$trans("email"), value: "email", class: "header-style" },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("verified_at"),
          value: "verified_at",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      shouldRefreshUsers: "user/shouldRefreshUsers",
    }),
    isLoadingInSelectMenu() {
      return this.isFetchingFile || this.isRemovingRecordsFile;
    },
    onSelectActions() {
      return [
        {
          title: {
            single: "send_customer_notification_title",
            many: "send_customers_notification_title",
          },
          action: this.sendCustomerNotification,
          icon: "$send",
          target: "user",
        },
        {
          title: {
            single: "remove_newsletter_record",
            many: "remove_newsletter_records",
          },
          action: this.handleRemoveMultiple,
          icon: "$trash-default",
          color: "error",
          target: "newsletter",
          class: "error--text",
        },
        {
          title: {
            single: "download_xlsx_title",
            many: "download_xlsx_title",
          },
          action: this.handleDownloadFile,
          icon: "$download",
          params: "xlsx",
          target: "newsletter",
        },
        {
          title: {
            single: "download_xls_title",
            many: "download_xls_title",
          },
          action: this.handleDownloadFile,
          icon: "$download",
          params: "xls",
          target: "newsletter",
        },
        {
          title: {
            single: "download_ods_title",
            many: "download_ods_title",
          },
          action: this.handleDownloadFile,
          icon: "$download",
          params: "ods",
          target: "newsletter",
        },
        {
          title: {
            single: "download_csv_title",
            many: "download_csv_title",
          },
          action: this.handleDownloadFile,
          icon: "$download",
          params: "csv",
          target: "newsletter",
        },
        {
          title: {
            single: "download_html_title",
            many: "download_html_title",
          },
          action: this.handleDownloadFile,
          icon: "$download",
          params: "html",
          target: "newsletter",
        },
      ];
    },
  },
  watch: {
    getRefreshNewsletterStatus(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchNewsletterList: "newsletter/fetchAll",
    }),
  },
};
</script>
