<template>
  <v-container id="newsletters" fluid tag="section">
    <newsletter-list />
  </v-container>
</template>

<script>
import NewsletterList from "@/views/dashboard/pages/Notifications/Newsletter/components/NewsletterList.vue";

export default {
  name: "Newsletters",
  components: { NewsletterList },
};
</script>
